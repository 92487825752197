/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import fetchFarms, { fetchFactoryPools } from './fetchFarms'
import {
  fetchFarmUserData,
} from './fetchFarmUser'
import { FarmsState, Farm, SupportCommToken, FarmUserData, UserSupportCommToken, PoolSpaceData } from '../types'
import { fetchSupportCommTokens, fetchUserSupportCommTokens } from './fetchCreatePoolOption'
import { fetchSpace } from './fetchSpace'
import { getCertificationPools } from './helpers'

const initialState: FarmsState = {
  data: [],
  loadFarmsData: false,
  userDataLoaded: false,
  userData: [],
  supportCommTokens: [
    {
      supportCommToken: '',
      poolAmounts: 0,
      editFeeValue: 0,
      closeFeeValue: 0,
      states: false,
      symbol: 'select',
    }
  ],
  userSupportCommTokens: [],
  spaceList: [],
  spaceLoaded: false,
  commonPoolConfig: {
    hiddenAprPoolIds: {}
  }
}

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<Farm[]>(
  'farms/fetchFarmsPublicDataAsync',
  async () => {
    try {
      const farms = await fetchFactoryPools()
      return farms
    }catch (e) {
      return []
    }
  },
)
export const fetchSupportCommTokensAsync = createAsyncThunk<SupportCommToken[]>(
  'farms/fetchSupportCommTokensAsync',
  async () => {
    try {
      const res = await fetchSupportCommTokens()
      return res
    }catch (e) {
      console.error('SupportCommTokens error - ',e)
      return []
    }
  },
)
export const fetchFarmUserDataAsync = createAsyncThunk<FarmUserData[],{account:string,farms: Farm[]}>(
  'farms/fetchFarmUserDataAsync',
  async (options:{account:string,farms: Farm[]}) => {
    try {
      const res = await fetchFarmUserData(options.farms,options.account)
      return res
    }catch (e) {
      console.error(e)
      return []
    }
  },
)
export const fetchUserSupportCommTokensAsync = createAsyncThunk<UserSupportCommToken[],{account:string,tokens: SupportCommToken[]}>(
  'farms/fetchUserSupportCommTokensAsync',
  async (options:{account:string,tokens: SupportCommToken[]}) => {
    try {
      const res = await fetchUserSupportCommTokens(options.tokens,options.account)
      return res
    }catch (e) {
      return []
    }
  },
)

// get pool space list
export const fetchPoolSpaceDataAsync = createAsyncThunk<PoolSpaceData[]>(
  'farms/fetchPoolSpaceDataAsync',
  async () => {
    try {
      const res = await fetchSpace()
      return res
    }catch (e) {
      return []
    }
  },
)

export const fetchCommonPoolConfig = createAsyncThunk(
  'farms/fetchCommonPoolConfig',
  async () => {
    return getCertificationPools()
  }
)

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      if(liveFarmsData.length>0) {
        state.data = liveFarmsData
      }
      state.loadFarmsData = true
    })
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      state.userDataLoaded = true
      state.userData = action.payload
    })
    builder.addCase(fetchSupportCommTokensAsync.fulfilled, (state, action) => {
      const res  = action.payload
      if(res.length){
        state.supportCommTokens = action.payload
      }
    })
    builder.addCase(fetchUserSupportCommTokensAsync.fulfilled, (state, action) => {
      const res  = action.payload
      if(res.length){
        state.userSupportCommTokens = action.payload
      }
    })
    builder.addCase(fetchPoolSpaceDataAsync.fulfilled, (state, action) => {
      const res  = action.payload
      if(res.length){
        state.spaceList = action.payload
      }
      state.spaceLoaded = true

    })
    builder.addCase(fetchCommonPoolConfig.fulfilled, (state, action) => {
      state.commonPoolConfig = action.payload
    })
  },
})

export default farmsSlice.reducer
